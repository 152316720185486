import { AddressesStoreState } from "@/interfaces/addresses/models";
import { Module } from "vuex";

const addressesModule: Module<AddressesStoreState, AddressesStoreState> = {
  state: {
    address: null,
  },
  mutations: {
    setAddress(state, payload) {
      state.address = payload;
    },
    resetAddressesState(state) {
      state.address = null;
    }
  },
  actions: {
    setAddress(context, payload) {
      context.commit("setAddress", payload);
    },
  },

  getters: {
    address(state) {
      return state.address;
    },
  },
};

export default addressesModule;
