import { CustomAlertOptions } from "@/interfaces/common/models";
import {
  ToastButton,
  toastController,
  alertController,
  modalController,
} from "@ionic/core/components";
import { NavigationGuardNext } from "vue-router";

export default class IonicUi {
  // Setup for toast / notification creation
  async createToast(message: string, error: boolean, retryFunction?: any) {
    const buttons: ToastButton[] = retryFunction
      ? [
          {
            text: "Probeer opnieuw",
            role: "retry",
            handler: () => {
              retryFunction();
            },
          },
          {
            text: "X",
            role: "cancel",
          },
        ]
      : [];

    const toast: HTMLIonToastElement = await toastController.create({
      message,
      duration: retryFunction ? 0 : 4000,
      position: "bottom",
      color: error ? "danger" : "success",
      buttons: buttons,
    });
    await toast.present();
  }

  // Setup for alert creation
  async createAlert(options: CustomAlertOptions) {
    return alertController.create({
      header: options.header,
      message: options.message,
      backdropDismiss: true,
      buttons: options.buttons,
      inputs: options.inputs ? options.inputs : [],
    });
  }

  createLeaveRouteAlert(
    condition: boolean,
    header: string,
    next: NavigationGuardNext
  ) {
    if (condition) {
      alertController
        .create({
          message: `Weet u zeker dat u deze pagina wil verlaten? Mogelijk openstaande wijzigingen worden niet opgeslagen.`,
          header,
          buttons: [
            {
              text: "Annuleren",
              role: "cancel",
              cssClass: "alertCancelButton",
              handler() {
                return next(false);
              },
            },
            {
              text: "Verlaten",
              role: "destructive",
              cssClass: "alertDeleteButton",
              handler() {
                return next();
              },
            },
          ],
        })
        .then((alertEl) => {
          alertEl.present();
        });
    } else {
      return next();
    }
  }

  // Setup for modal creation
  async createModal(
    component: any,
    componentProps?: object,
    cssClass?: string
  ) {
    const modal: HTMLIonModalElement = await modalController.create({
      component: component,
      cssClass: cssClass ? cssClass : "",
      componentProps: componentProps,
    });
    modal.present();
  }

  // Dismis modal
  async dismisModal() {
    await modalController.dismiss();
  }
}
