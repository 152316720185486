import { AuthStoreState } from "@/interfaces/auth/models";
import { Module } from "vuex";

const authModule: Module<AuthStoreState, AuthStoreState> = {
  state: {
    user: null,
    role: null,
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },
    setRole(state, payload) {
      state.role = payload;
    },
    resetAuthState(state) {
      state.user = null;
      state.role = null;
    }
  },
  actions: {
    setUser(context, payload) {
      context.commit("setUser", payload);
    },
    setRole(context, payload) {
      context.commit("setRole", payload);
    },
  },

  getters: {
    user(state) {
      return state.user;
    },
    userRole(state) {
      return state.role;
    },
  },
};

export default authModule;
