import { createStore } from "vuex";
import usersModule from "./modules/users";
import authModule from "./modules/auth";
import regionsModule from "./modules/regions";
import workAreasModule from "./modules/work-areas";
import navigationModule from "./modules/navigation"
import addressesModule from "./modules/addresses";
import maintenanceServicesModule from "./modules/maintenance-services";
import companiesModule from "./modules/companies";

export default createStore<any>({
  modules: {
    users: usersModule,
    auth: authModule,
    regions: regionsModule,
    workAreas: workAreasModule,
    navigation: navigationModule,
    addresses: addressesModule,
    maintenanceServices: maintenanceServicesModule,
    companies: companiesModule,
  },
  actions: {
    // Resets store for new user
    logout(context) {
      context.commit("resetUsersState");
      context.commit("resetAuthState");
      context.commit("resetRegionsState");
      context.commit("resetWorkAreasState");
      context.commit("resetAddressesState");
      context.commit("resetMaintenanceServicesState");
      context.commit("resetCompaniesState");
    },
  }
});
