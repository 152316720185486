import { createRouter } from "@ionic/vue-router";
import {
  createWebHashHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  Router,
  RouteRecordRaw,
} from "vue-router";
import store from "@/store/index";
import UserService from "@/services/UserService";
import RegionService from "@/services/RegionService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/:catchAll(.*)*",
    redirect: "/404",
  },
  {
    path: "/404",
    component: () => import("@/pages/shared/404Page.vue"),
  },
  {
    name: "no-region",
    path: "/no-region",
    component: () => import("@/pages/manager/regions/NoRegionPage.vue"),
    meta: {
      authorize: ["manager"],
    },
  },
  {
    path: "/",
    redirect: "/home",
  },
  {
    name: "auth",
    path: "/auth",
    component: () => import("@/pages/shared/AuthPage.vue"),
  },
  {
    name: "forgot password",
    path: "/forgot-password",
    component: () => import("@/pages/shared/ForgotPasswordPage.vue")
  },
  {
    name: "reset password",
    path: "/reset-password",
    component: () => import("@/pages/shared/ResetPasswordPage.vue")
  },
  {
    path: "/home",
    component: () => import("@/pages/shared/HomePage.vue"),
    meta: {
      authorize: [],
    },
  },
  {
    name: "show region",
    path: "/regions/index/:id",
    component: () => import("@/pages/admin/regions/EditRegionPage.vue"),
    meta: {
      authorize: ["admin"],
    },
  },
  {
    name: "show user",
    path: "/users/index/:id",
    component: () => import("@/pages/shared/users/UserProfilePage.vue"),
    meta: {
      authorize: ["admin", "manager"],
    },
  },
  {
    path: "/my-profile",
    component: () => import("@/pages/shared/profile/MyProfilePage.vue"),
    meta: {
      authorize: [],
    },
  },
  {
    path: "/companies/",
    component: () => import("@/pages/admin/companies/CompanyTabs.vue"),
    meta: {
      authorize: ["admin"],
    },
    children: [
      {
        path: "",
        redirect: "/companies/index",
      },
      {
        path: "index",
        component: () => import("@/pages/admin/companies/CompanyIndexPage.vue"),
      },
    ],
  },
  {
    path: "/regions/",
    component: () => import("@/pages/admin/regions/RegionTabs.vue"),
    meta: {
      authorize: ["admin"],
    },
    children: [
      {
        path: "",
        redirect: "/regions/index",
      },
      {
        path: "index",
        component: () => import("@/pages/admin/regions/RegionIndexPage.vue"),
      },

      {
        path: "add-region",
        component: () => import("@/pages/admin/regions/RegionAddPage.vue"),
      },
    ],
  },
  {
    path: "/users/",
    component: () => import("@/pages/shared/users/UserTabs.vue"),
    meta: {
      authorize: ["admin", "manager"],
    },
    children: [
      {
        path: "",
        redirect: "/users/index",
      },
      {
        path: "index",
        component: () => import("@/pages/shared/users/UserIndexPage.vue"),
      },
      {
        path: "add-manager",
        component: () => import("@/pages/admin/users/AddManagerPage.vue"),
        meta: {
          authorize: ["admin"],
        },
      },
      {
        path: "add-mechanic",
        component: () => import("@/pages/manager/users/AddMechanicPage.vue"),
        meta: {
          authorize: ["manager"],
        },
      },
    ],
  },
  {
    path: "/maintenance-services/",
    component: () =>
      import("@/pages/shared/maintenance-services/MaintenanceServiceTabs.vue"),
    meta: {
      authorize: ["client", "mechanic", "manager"],
    },
    children: [
      {
        path: "",
        redirect: "/maintenance-services/index",
      },
      {
        path: "index",
        component: () =>
          import(
            "@/pages/shared/maintenance-services/MaintenanceServiceIndexPage.vue"
          ),
      },
      {
        path: "proposals",
        component: () =>
          import(
            "@/pages/mechanic/maintenance-services/MaintenanceServiceProposalIndexPage.vue"
          ),
        meta: {
          authorize: ["mechanic"],
        },
      },
      {
        path: "add-maintenance-service",
        component: () =>
          import(
            "@/pages/client/maintenance-services/AddMaintenanceServicePage.vue"
          ),
        meta: {
          authorize: ["client"],
        },
      },
    ],
  },
  {
    path: "/billing/",
    component: () => import("@/pages/client/billing/BillingTabs.vue"),
    meta: {
      authorize: ["client"],
    },
    children: [
      {
        path: "",
        redirect: "/billing/index",
      },
      {
        path: "index",
        component: () =>
          import(
            "@/pages/client/billing/BillingIndexPage.vue"
          ),
      },
    ],
  },
  {
    name: "activate subscription",
    path: "/billing/subscription/activate",
    component: () =>
      import(
        "@/pages/client/billing/ActivateSubscriptionPage.vue"
      ),
    meta: {
      authorize: ["client"],
    },
  },
  {
    name: "show maintenance service",
    path: "/maintenance-services/index/:id",
    component: () =>
      import(
        "@/pages/shared/maintenance-services/ShowMaintenanceServicePage.vue"
      ),
    meta: {
      authorize: ["mechanic", "manager", "client"],
    },
  },
];

const router: Router = createRouter({
  history: createWebHashHistory(),
  routes,
});

// Check if user is authenticated, navigate to login if not (api/index.ts)
// Check if user is authorized, navigate to home if not
router.beforeEach(
  async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) => {
    const { authorize } = to.meta;
    let currentUserRole: string = store.getters.userRole;

    if (to.path !== "/auth") {
      store.dispatch("setRedirectTo", to.path);
    }

    if (authorize) {
      if (!currentUserRole) {
        await UserService.getUser()
          .then(async () => {
            currentUserRole = store.getters.userRole;
            const currentUser = store.getters.user;
            if (
              currentUserRole !== "admin" &&
              currentUserRole !== "manager" &&
              currentUser.region_id
            ) {
              await RegionService.getCurrentRegion(currentUser.region_id);
            }
            if (authorize.length && !authorize.includes(currentUserRole)) {
              return next({ path: "/home" });
            }
          })
          .catch(() => {
            return;
          });
      } else {
        if (authorize.length && !authorize.includes(currentUserRole)) {
          return next({ path: "/home" });
        }
      }
    }
    return next();
  }
);

export default router;
