
import { IonApp, IonRouterOutlet, toastController } from "@ionic/vue";
import { defineComponent, ref, watch } from "vue";
import SideNavComponent from "@/components/common/SideNavComponent.vue";
import store from "./store";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
    SideNavComponent,
  },
  setup() {
    // Component variables
    const role = ref(null);
    const showBackdrop = ref(false);

    // Get role and update if necessary
    watch(
      () => store.getters.userRole,
      function () {
        role.value = store.getters.userRole;
      }
    );

    async function showToast() {
      const toast: any = await toastController.create({
        message: 'showToast',
        duration: 1,
        position: "bottom",
        color: "success",
        cssClass: "dp__arrow_top"
      });
      await toast.present();
    }
    showToast();

    // Get backdrop status and update if necessary
    watch(
      () => store.getters.showBackdrop,
      function () {
        showBackdrop.value = store.getters.showBackdrop;
      }
    );
    role.value = store.getters.userRole;

    // Return data
    return {
      role,
      showBackdrop,
    };
  },
});
