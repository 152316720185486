export default {
  "auth": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inloggen"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registreren"])},
    "credentialsFalse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De opgegeven gebruikersnaam of wachtwoord is onjuist"])}
  },
  "form": {
    "fields": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadres"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefoonnummer"])},
      "streetName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straat"])},
      "houseNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huisnummer"])},
      "houseNumberAddition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toevoeging"])},
      "passwordConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord bevestigen"])},
      "termsOfService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik ga akkoord met de algemene voorwaarden"])}
    }
  },
  "validations": {
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit veld is verplicht"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit veld dient een valide e-mailadres te zijn"])},
    "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dit veld dient minimaal ", _interpolate(_named("min")), " karakters lang te zijn"])},
    "maxLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dit veld dient maximaal ", _interpolate(_named("max")), " karakters lang te zijn"])},
    "sameAsPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit veld moet dezelfde waarde hebben als het wachtwoord veld"])},
    "requiredIfPasswordGiven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit veld is verplicht omdat het wachtwoord veld is ingevuld"])},
    "requiredIfConfirmPasswordGiven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit veld is verplicht omdat het wachtwoord bevestigen veld is ingevuld"])},
    "minValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["De waarde van dit veld moet groter zijn dan ", _interpolate(_named("min"))])},
    "maxValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["De waarde van dit veld moet kleiner zijn dan ", _interpolate(_named("max"))])},
    "isChecked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit veld moet aangevinkt worden"])}
  }
}