import { RegionsStoreState } from "@/interfaces/regions/models";
import { Module } from "vuex";

const regionsModule: Module<RegionsStoreState, RegionsStoreState> = {
  state: {
    regions: null,
    regionsIndex: null,
    regionIndexPagination: null,
    currentRegion: null,
  },
  mutations: {
    setRegions(state, payload) {
      state.regions = payload;
    },
    setRegionsIndex(state, payload) {
      state.regionsIndex = payload;
    },
    pushRegionsIndex(state, payload) {
      if (state.regionsIndex) {
        state.regionsIndex = [...state.regionsIndex, ...payload];
      }
    },
    setRegionIndexPagination(state, payload) {
      state.regionIndexPagination = payload;
    },
    setCurrentRegion(state, payload) {
      state.currentRegion = payload;
    },
    clearCurrentRegion(state) {
      state.currentRegion = null;
    },
    resetRegionsState(state) {
      state.regions = null;
      state.currentRegion = null;
    },
  },
  actions: {
    setRegions(context, payload) {
      context.commit("setRegions", payload);
    },
    setRegionsIndex(context, payload) {
      context.commit("setRegionsIndex", payload.data);
      context.commit("setRegionIndexPagination", payload);
    },
    pushRegionsIndex(context, payload) {
      context.commit("pushRegionsIndex", payload.data);
      context.commit("setRegionIndexPagination", payload);
    },
    setCurrentRegion(context, payload) {
      context.commit("setCurrentRegion", payload);
    },
    leaveEditRegions(context) {
      context.commit("clearCurrentRegion");
      context.commit("clearManagers");
      context.commit("clearWorkAreas");
    },
  },
  getters: {
    regions(state) {
      return state.regions;
    },
    regionsIndex(state) {
      return state.regionsIndex;
    },
    regionIndexPagination(state) {
      return state.regionIndexPagination;
    },
    currentRegion(state) {
      return state.currentRegion;
    },
  },
};

export default regionsModule;
