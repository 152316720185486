import http from "@/utils/api";
import store from "@/store/index";
import {
  CreateRegionForm,
  EditRegionForm,
  RegionFilter,
  WorkArea,
} from "@/interfaces/regions/models";
import IonicUi from "@/utils/ui";

const ui = new IonicUi();

export default {
  // Sends a http GET request to the server
  // Retrieves all regions as pagination
  // Return a promise
  // On 2XX response store the data
  getRegionsIndex(params?: RegionFilter) {
    return new Promise(function (resolve, reject) {
      http
        .get("/api/regions-paginated", { params })
        .then((res) => {
          if (params) {
            if (params.page === 1) {
              store.dispatch("setRegionsIndex", res.data.regions);
            } else {
              store.dispatch("pushRegionsIndex", res.data.regions);
            }
          } else {
            store.dispatch("setRegionsIndex", res.data.regions);
          }
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Sends a http GET request to the server
  // Retrieves all regions
  // Return a promise
  // On 2XX response store the data
  getRegions() {
    return new Promise(function (resolve, reject) {
      http
        .get("/api/regions")
        .then((res) => {
          store.dispatch("setRegions", res.data.regions);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Sends a http GET request to the server
  // Retrieve a region based on ID
  // Return a promise
  // On 2XX response store the data
  getCurrentRegion(regionId: string) {
    return new Promise(function (resolve, reject) {
      http
        .get(`/api/regions/${regionId}`)
        .then((res) => {
          store.dispatch("setCurrentRegion", res.data.region);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Sends a http POST request to the server
  // Store a new region
  // Return a promise
  // Create a notification based on the response
  createRegion(payload: CreateRegionForm, retryFunction: any) {
    return new Promise(function (resolve, reject) {
      http
        .post("/api/regions", payload)
        .then((res) => {
          ui.createToast("De regio is succesvol aangemaakt", false);
          resolve(res);
        })
        .catch((err) => {
          ui.createToast(
            "Er is een fout opgetreden bij het aanmaken van een nieuwe regio",
            true,
            function () {
              retryFunction();
            }
          );
          reject(err);
        });
    });
  },

  // Sends a http PUT request to the server
  // Update the region's work areas based on ID
  // Return a promise
  // Create a notification based on the response
  bindWorkAreas(
    regionId: string | string[],
    payload: WorkArea[],
    retryFunction: any
  ) {
    return new Promise(function (resolve, reject) {
      http
        .put(`/api/regions/${regionId}/work-areas`, {
          workAreas: payload,
        })
        .then((res) => {
          ui.createToast(
            `De wijzigingen aan de werkgebieden zijn succesvol toegepast!`,
            false
          );
          resolve(res);
        })
        .catch((err) => {
          ui.createToast(
            `Er is een fout opgetreden bij het doorvoeren van de wijzigingen aan de werkgebieden.`,
            true,
            function () {
              retryFunction();
            }
          );
          reject(err);
        });
    });
  },

  // Sends a http PUT request to the server
  // Update a region based on ID
  // Return a promise
  // Create a notification based on the response
  editRegion(
    regionId: string | string[],
    payload: EditRegionForm,
    retryFunction: any
  ) {
    return new Promise(function (resolve, reject) {
      http
        .put(`/api/regions/${regionId}`, payload)
        .then((res) => {
          ui.createToast("De regio is succesvol gewijzigd", false);
          resolve(res);
        })
        .catch((err) => {
          ui.createToast(
            "Er is een fout opgetreden bij het wijzigen van een regio",
            true,
            function () {
              retryFunction();
            }
          );
          reject(err);
        });
    });
  },

  // Sends a http DELETE request to the server
  // Delete a region based on ID
  // Return a promise
  // Create a notification based on the response
  deleteRegion(regionId: string, retryFunction: any) {
    return new Promise(function (resolve, reject) {
      http
        .delete(`/api/regions/${regionId}`)
        .then((res) => {
          ui.createToast(`De regio is succesvol verwijderd`, false);
          resolve(res);
        })
        .catch((err) => {
          ui.createToast(
            "Er is een fout opgetreden bij het verwijderen van de regio",
            true,
            function () {
              retryFunction();
            }
          );
          reject(err);
        });
    });
  },
};
