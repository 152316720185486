import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-426d9950"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  id: "backdrop"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_side_nav_component = _resolveComponent("side-nav-component")!
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_app = _resolveComponent("ion-app")!

  return (_openBlock(), _createBlock(_component_ion_app, null, {
    default: _withCtx(() => [
      (_ctx.role)
        ? (_openBlock(), _createBlock(_component_side_nav_component, {
            key: 0,
            role: _ctx.role
          }, null, 8, ["role"]))
        : _createCommentVNode("", true),
      _createVNode(_component_ion_router_outlet, { id: "main-content" }),
      (_ctx.showBackdrop)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}