import { MaintenanceServicesStoreState } from "@/interfaces/maintenance-services/models";
import { Module } from "vuex";

const maintenanceServicesModule: Module<
  MaintenanceServicesStoreState,
  MaintenanceServicesStoreState
> = {
  state: {
    maintenanceService: null,
    maintenanceServices: null,
    maintenanceServiceIndexPagination: null,
    maintenanceServiceProposals: null,
    maintenanceServiceProposalIndexPagination: null,
  },
  mutations: {
    setMaintenanceService(state, payload) {
      state.maintenanceService = payload;
    },
    clearMaintenanceService(state) {
      state.maintenanceService = null;
    },
    setMaintenanceServiceProposals(state, payload) {
      state.maintenanceServiceProposals = payload;
    },
    pushMaintenanceServiceProposals(state, payload) {
      if (state.maintenanceServiceProposals) {
        state.maintenanceServiceProposals = [...state.maintenanceServiceProposals, ...payload];
      }
    },
    setMaintenanceServices(state, payload) {
      state.maintenanceServices = payload;
    },
    pushMaintenanceServices(state, payload) {
      if (state.maintenanceServices) {
        state.maintenanceServices = [...state.maintenanceServices, ...payload];
      }
    },
    clearMaintenanceServiceProposals(state) {
      state.maintenanceServiceProposals = null;
    },
    setMaintenanceServiceProposalIndexPagination(state, payload) {
      state.maintenanceServiceProposalIndexPagination = payload;
    },
    setMaintenanceServiceIndexPagination(state, payload) {
      state.maintenanceServiceIndexPagination = payload;
    },
    resetMaintenanceServicesState(state) {
      state.maintenanceService = null;
      state.maintenanceServices = null;
      state.maintenanceServiceIndexPagination = null;
      state.maintenanceServiceProposals = null;
      state.maintenanceServiceProposalIndexPagination = null;
    },
  },
  actions: {
    setMaintenanceService(context, payload) {
      context.commit("setMaintenanceService", payload);
    },
    setMaintenanceServiceProposals(context, payload) {
      context.commit("setMaintenanceServiceProposals", payload.data);
      context.commit("setMaintenanceServiceProposalIndexPagination", payload);
    },
    pushMaintenanceServiceProposals(context, payload) {
      context.commit("pushMaintenanceServiceProposals", payload.data);
      context.commit("setMaintenanceServiceProposalIndexPagination", payload);
    },
    setMaintenanceServices(context, payload) {
      context.commit("setMaintenanceServices", payload.data);
      context.commit("setMaintenanceServiceIndexPagination", payload);
    },
    pushMaintenanceServices(context, payload) {
      context.commit("pushMaintenanceServices", payload.data);
      context.commit("setMaintenanceServiceIndexPagination", payload);
    },
  },
  getters: {
    maintenanceService(state) {
      return state.maintenanceService;
    },
    maintenanceServices(state) {
      return state.maintenanceServices;
    },
    maintenanceServiceIndexPagination(state) {
      return state.maintenanceServiceIndexPagination;
    },
    maintenanceServiceProposals(state) {
      return state.maintenanceServiceProposals;
    },
    maintenanceServiceProposalIndexPagination(state) {
      return state.maintenanceServiceProposalIndexPagination;
    },
  },
};

export default maintenanceServicesModule;
