import { NavigationStoreState } from "@/interfaces/router/models";
import { Module } from "vuex";

const navigationModule: Module<NavigationStoreState, NavigationStoreState> = {
  state: {
    redirectTo: "",
    showBackdrop: false,
  },
  mutations: {
    setRedirectTo(state, payload) {
      state.redirectTo = payload;
    },
    changeBackdrop(state, payload) {
      state.showBackdrop = payload;
    },
  },
  actions: {
    setRedirectTo(context, payload) {
      context.commit("setRedirectTo", payload);
    },
    changeBackdrop(context, payload) {
      context.commit("changeBackdrop", payload);
    },
  },
  getters: {
    redirectTo(state) {
      return state.redirectTo;
    },
    showBackdrop(state) {
      return state.showBackdrop;
    },
  },
};

export default navigationModule;
