// This file is based on an online article
// Ravankole, S. (2020, 24 december). Intercepting requests & responses using axios. Clairvoyant. Geraadpleegd op 13 november 2021, van https://blog.clairvoyantsoft.com/intercepting-requests-responses-using-axios-df498b6cab62

import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import router from "@/router/index";
import store from "@/store";

// Step-1: Create a new Axios instance with a custom config.
const customAxios = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    "Content-type": "application/json",
    Accept: "application/json",
  },
  withCredentials: true,
});

// Step-2: Create request, response & error handlers
const requestHandler = (request: AxiosRequestConfig<any>) => {
  // Token will be dynamic so we can use any app-specific way to always
  // fetch the new token before making the call
  // if (!request.headers) {
  //   request.headers = {};
  // }
  // request.headers.Authorization = 'Bearer ' + store.getters.userToken;

  return request;
};

const responseHandler = (response: AxiosResponse<any, any>) => {
  if (response.status === 401) {
    router.replace({name: "auth"});
  }
  return response;
};

const errorHandler = (error: any) => {
  if (error.response.status === 401) {
    router.replace({
      name: "auth",
    });
  } else if (error.response.status === 418 && store.getters.userRole === 'manager') {
    router.replace({
      name: "no-region",
    });  
  }
  return Promise.reject(error);
};

// Step-3: Configure/make use of request & response interceptors from Axios
// Note: You can create one method say configureInterceptors, add below in that,
// export and call it in an init function of the application/page.
customAxios.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

customAxios.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);

// Step-4: Export the newly created Axios instance to be used in different locations.
export default customAxios;
