
import { Page } from "@/interfaces/common/models";
import store from "@/store/index";
import {
  logOutOutline,
  callOutline,
  homeOutline,
  earthOutline,
  peopleOutline,
  personOutline,
  constructOutline,
  businessOutline,
} from "ionicons/icons";
import {
  IonMenuToggle,
  IonItem,
  IonMenu,
  IonHeader,
  IonToolbar,
  IonContent,
  IonButtons,
  IonMenuButton,
  IonButton,
  IonIcon,
  IonFooter,
  menuController,
} from "@ionic/vue";
import { defineComponent, Ref, ref } from "@vue/runtime-core";
import ImageComponent from "../common/ImageComponent.vue";
import AuthService from "@/services/AuthService";
import router from "@/router";

export default defineComponent({
  props: {
    role: {
      type: String,
    },
  },
  components: {
    IonMenuToggle,
    IonItem,
    IonMenu,
    IonHeader,
    IonToolbar,
    IonContent,
    IonButtons,
    IonMenuButton,
    IonButton,
    ImageComponent,
    IonIcon,
    IonFooter,
  },
  setup(props) {
    // Component variables
    const pages: Ref<Page[]> = ref([
      {
        icon: homeOutline,
        title: "Home",
        url: "/home",
      },
    ]);
    const footerPages: Ref<Page[]> = ref([
      {
        icon: callOutline,
        title: "Contact",
        url: "/my-profile",
      },
    ]);

    // Set pages based on user role
    if (store.getters.user.active) {
      switch (props.role) {
        case "admin":
          pages.value = pages.value.concat([
            {
              icon: peopleOutline,
              title: "Gebruikersbeheer",
              url: "/users",
            },
            {
              icon: earthOutline,
              title: "Regio's en werkgebieden",
              url: "/regions",
            },
            {
              icon: businessOutline,
              title: "Bedrijven",
              url: "/companies",
            },
          ]);
          break;
        case "manager":
          pages.value = pages.value.concat([
            {
              icon: peopleOutline,
              title: "Gebruikersbeheer",
              url: "/users",
            },
            {
              icon: constructOutline,
              title: "Onderhoudsbeurten",
              url: "/maintenance-services",
            },
          ]);
          break;
        case "mechanic":
          pages.value = pages.value.concat([
            {
              icon: constructOutline,
              title: "Onderhoudsbeurten",
              url: "/maintenance-services",
            },
          ]);
          break;
        case "client":
          pages.value = pages.value.concat([
            {
              icon: constructOutline,
              title: "Onderhoudsbeurten",
              url: "/maintenance-services",
            },
          ]);
      }

      // Set my profile page on bottom
      pages.value = pages.value.concat([
        {
          icon: personOutline,
          title: "Mijn profiel",
          url: "/my-profile",
        },
      ]);
    }

    // Redirect the user based on page selected
    function redirect(url: Page["url"]) {
      menuController.toggle();
      router.push(url);
    }

    // Log out user and programmatically toggle menu
    function logOut() {
      menuController.toggle();
      AuthService.logout();
    }

    // Return data and functions
    return {
      logOut,
      redirect,
      logOutOutline,
      pages,
      footerPages,
    };
  },
});
