import { WorkAreasStoreState } from "@/interfaces/work-areas/models";
import { Module } from "vuex";

const workAreasModule: Module<WorkAreasStoreState, WorkAreasStoreState> = {
  state: {
    workAreas: null,
  },
  mutations: {
    updateWorkAreas(state, payload) {
      state.workAreas = payload;
    },
    clearWorkAreas(state) {
      state.workAreas = null;
    },
    resetWorkAreasState(state) {
      state.workAreas = null;
    },
  },
  actions: {
    setWorkAreas(context, payload) {
      context.commit("updateWorkAreas", payload)
    },
  },

  getters: {
    workAreas(state) {
      return state.workAreas;
    },
  },
};

export default workAreasModule;
