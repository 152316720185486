import http from "@/utils/api";
import {
  LoginForm,
  PasswordForgotForm,
  RegisterClientForm,
  ResetPasswordForm,
} from "@/interfaces/auth/models";
import store from "@/store";
import IonicUi from "@/utils/ui";

const ui = new IonicUi();

export default {
  // Sends a http GET request to the server
  // Get CSRF token, used for authorization
  getCSRFToken() {
    http.get("/sanctum/csrf-cookie");
  },

  // Sends a http POST request to the server
  // Attempts a login
  // Return a promise
  login(payload: LoginForm) {
    return new Promise(function (resolve, reject) {
      http
        .post("/login", payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Sends a http POST request to the server
  // Logout currently logged in user
  // Return a promise
  // On 2XX response empty store and push to auth page
  logout() {
    return new Promise(function (resolve, reject) {
      http
        .post("/logout")
        .then((res) => {
          window.location.assign("/");
          store.dispatch("setRedirectTo", "/home");
          store.dispatch("logout");
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Sends a http POST request to the server
  // Store a new client
  // Return a promise
  // Create a notification based on the response
  registerClient(payload: RegisterClientForm, retryFunction: any) {
    return new Promise(function (resolve, reject) {
      http
        .post("/api/users/client", payload)
        .then((res) => {
          ui.createToast(
            `Uw account is succesvol aangemaakt. Controleer uw mailbox voor verdere instructies of log direct in!`,
            false
          );
          resolve(res);
        })
        .catch((err) => {
          if (err.response.status !== 404) {
            ui.createToast(
              `Er is een fout opgetreden bij het aanmaken van uw account.`,
              true,
              function () {
                retryFunction();
              }
            );
          }
          reject(err);
        });
    });
  },

  forgotPassword(payload: PasswordForgotForm) {
    return new Promise(function (resolve, reject) {
      http
        .post("/forgot-password", payload)
        .then((res) => {
          ui.createToast(
            `Indien uw mailadres bij ons bekend is ontvangt een wachtwoord reset-link in uw mailbox!`,
            false
          );
          resolve(res);
        })
        .catch((err) => {
          ui.createToast(
            `Er is een fout opgetreden bij het aanvragen van een wachtwoord reset-link.`,
            true
          );
          reject(err);
        });
    });
  },

  resetPassword(payload: ResetPasswordForm) {
    return new Promise(function (resolve, reject) {
      http
        .post("/reset-password", payload)
        .then((res) => {
          ui.createToast(
            `Uw wachtwoord is succesvol gewijzigd!`,
            false
          );
          resolve(res);
        })
        .catch((err) => {
          ui.createToast(
            `Er is een fout opgetreden bij het wijzigen van uw wachtwoord.`,
            true
          );
          reject(err);
        });
    });
  },
};
