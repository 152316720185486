import http from "@/utils/api";
import store from "@/store/index";
import IonicUi from "@/utils/ui";
import {
  CreateManagerForm,
  CreateMechanicForm,
  User,
  UserFilters,
} from "@/interfaces/users/models";
import { EditPasswordForm, EditProfileForm } from "@/interfaces/profile/models";
import { WorkArea } from "@/interfaces/regions/models";
import { EditAddressForm } from "@/interfaces/addresses/models";

const ui = new IonicUi();

export default {
  // Sends a http GET request to the server
  // Retrieves the currently logged in user
  // Return a promise
  // On 2XX response store the data
  getUser() {
    return new Promise(function (resolve, reject) {
      http
        .get("/api/user")
        .then((res) => {
          store.dispatch("setUser", res.data.user);
          store.dispatch("setRole", res.data.role);
          store.dispatch("setAddress", res.data.address);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Sends a http GET request to the server
  // Retrieves all the users
  // Applies filters
  // Return a promise
  // On 2XX response store the data
  getUserIndex(params?: UserFilters) {
    return new Promise(function (resolve, reject) {
      http
        .get("/api/users", { params })
        .then((res) => {
          if (params) {
            if (params.page === 1) {
              store.dispatch("setUsers", res.data.users);
            } else {
              store.dispatch("pushUsers", res.data.users);
            }
          } else {
            store.dispatch("setUsers", res.data.users);
          }
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Sends a http GET request to the server
  // Retrieves a user based on ID
  // Return a promise
  // On 2XX response store the data
  // On invalid response create notification
  getUserProfile(userId: string, retryFunction: any) {
    return new Promise(function (resolve, reject) {
      http
        .get(`/api/users/${userId}`)
        .then((res) => {
          store.dispatch("setUserProfile", res.data.user);
          resolve(res);
        })
        .catch((err) => {
          ui.createToast(
            "Er is een fout opgetreden bij het ophalen van de gebruiker",
            true,
            function () {
              retryFunction();
            }
          );
          reject(err);
        });
    });
  },

  // Sends a http PUT request to the server
  // Changes the active state of a user based on ID
  // Return a promise
  // Create a notification based on the response
  changeActiveState(userId: string | string[], active: boolean) {
    return new Promise(function (resolve, reject) {
      http
        .put(`/api/users/${userId}/activate`)
        .then((res) => {
          ui.createToast(
            `De gebruiker is succesvol als ${
              active ? "actief" : "inactief"
            } gemarkeerd!`,
            false
          );
          resolve(res);
        })
        .catch((err) => {
          ui.createToast(
            `Er is een fout opgetreden bij het markeren van de gebruiker als ${
              active ? "actief" : "inactief"
            }.`,
            true
          );
          reject(err);
        });
    });
  },

  // Sends a http PUT request to the server
  // Updates the currently logged in user
  // Return a promise
  // Create a notification based on the response
  // On 2XX response store the data
  updateAuthUser(payload: EditProfileForm, retryFunction: any) {
    return new Promise(function (resolve, reject) {
      http
        .put("/api/profile", payload)
        .then((res) => {
          ui.createToast("Uw profiel is succesvol gewijzigd", false);
          store.dispatch("setUser", res.data.user);
          resolve(res);
        })
        .catch((err) => {
          ui.createToast(
            "Er is een fout opgetreden bij het wijzigen van uw profiel",
            true,
            function () {
              retryFunction();
            }
          );
          reject(err);
        });
    });
  },

  // Sends a http PUT request to the server
  // Updates the currently logged in user
  // Return a promise
  // Create a notification based on the response
  // On 2XX response store the data
  updateUser(userId: User["id"], payload: EditProfileForm, retryFunction: any) {
    return new Promise(function (resolve, reject) {
      http
        .put(`/api/users/${userId}`, payload)
        .then((res) => {
          ui.createToast("Het gebruikersprofiel is succesvol gewijzigd", false);
          resolve(res);
        })
        .catch((err) => {
          ui.createToast(
            "Er is een fout opgetreden bij het wijzigen van de gebruikersgegevens",
            true,
            function () {
              retryFunction();
            }
          );
          reject(err);
        });
    });
  },

  // Sends a http PUT request to the server
  // Updates the currently logged in user
  // Return a promise
  // Create a notification based on the response
  // On 2XX response store the data
  updateAddress(
    userId: User["id"],
    payload: EditAddressForm,
    retryFunction: any
  ) {
    return new Promise(function (resolve, reject) {
      http
        .put(`/api/users/${userId}/address`, payload)
        .then((res) => {
          ui.createToast(
            "Het adres van de gebruiker is succesvol gewijzigd",
            false
          );
          resolve(res);
        })
        .catch((err) => {
          let errorMessage =
            "Er is een fout opgetreden bij het wijzigen van het adres van de gebruiker";
          if (err.response.status === 404) {
            errorMessage =
              "Het adres kan niet gewijzigd worden. Momenteel is ZekerZon niet werkzaam binnen dit postcodegebied.";
          }
          ui.createToast(errorMessage, true, function () {
            retryFunction();
          });
          reject(err);
        });
    });
  },

  // Sends a http PUT request to the server
  // Updates the currently logged in user's password
  // Create a notification based on the response
  async updatePassword(payload: EditPasswordForm, retryFunction: any) {
    return new Promise(function (resolve, reject) {
      http
        .put("/api/password", payload)
        .then((res) => {
          ui.createToast("Uw wachtwoord is succesvol gewijzigd", false);
          resolve(res);
        })
        .catch((err) => {
          ui.createToast(
            "Er is een fout opgetreden bij het wijzigen van uw wachtwoord",
            true,
            function () {
              retryFunction();
            }
          );
          reject(err);
        });
    });
  },

  // Sends a http POST request to the server
  // Stores a new manager
  // Return a promise
  // Create a notification based on the response
  storeManager(payload: CreateManagerForm, retryFunction: any) {
    return new Promise(function (resolve, reject) {
      http
        .post("/api/users/manager", payload)
        .then((res) => {
          ui.createToast("De regiomanager is succesvol geregistreerd", false);
          resolve(res);
        })
        .catch((err) => {
          ui.createToast(
            "Er is een fout opgetreden bij het aanmaken van de regiomanager",
            true,
            function () {
              retryFunction();
            }
          );
          reject(err);
        });
    });
  },

  // Sends a http POST request to the server
  // Stores a new mechanic
  // Return a promise
  // Create a notification based on the response
  storeMechanic(payload: CreateMechanicForm, retryFunction: any) {
    return new Promise(function (resolve, reject) {
      http
        .post("/api/users/mechanic", payload)
        .then((res) => {
          ui.createToast("De monteur is succesvol geregistreerd", false);
          resolve(res);
        })
        .catch((err) => {
          ui.createToast(
            "Er is een fout opgetreden bij het aanmaken van de monteur",
            true,
            function () {
              retryFunction();
            }
          );
          reject(err);
        });
    });
  },

  // Sends a http GET request to the server
  // Retrieves all users with the manager role
  // Return a promise
  // On 2XX response store data
  // Create a notification on invalid response
  getManagers(retryFunction: any) {
    return new Promise(function (resolve, reject) {
      http
        .get("/api/users/managers")
        .then((res) => {
          store.dispatch("updateManagers", res.data.managers);
          resolve(res);
        })
        .catch((err) => {
          ui.createToast(
            "Er is een fout opgetreden bij het ophalen van de managers",
            true,
            function () {
              retryFunction();
            }
          );
          reject(err);
        });
    });
  },

  // Sends a http GET request to the server
  // Retrieves all users with the mechanic role within region
  // Return a promise
  // On 2XX response store data
  // Create a notification on invalid response
  getMechanics(retryFunction: any) {
    return new Promise(function (resolve, reject) {
      http
        .get("/api/users/mechanics")
        .then((res) => {
          store.dispatch("updateMechanics", res.data.mechanics);
          resolve(res);
        })
        .catch((err) => {
          ui.createToast(
            "Er is een fout opgetreden bij het ophalen van de monteurs",
            true,
            function () {
              retryFunction();
            }
          );
          reject(err);
        });
    });
  },

  // Sends a http PUT request to the server
  // Updates user's work areas
  // Return a promise
  // Create a notification based on the response
  bindWorkAreas(
    userId: string | string[],
    payload: WorkArea[],
    retryFunction: any
  ) {
    return new Promise(function (resolve, reject) {
      http
        .put(`/api/users/${userId}/work-areas`, {
          workAreas: payload,
        })
        .then((res) => {
          ui.createToast(
            `De wijzigingen aan de werkgebieden zijn succesvol toegepast!`,
            false
          );
          resolve(res);
        })
        .catch((err) => {
          ui.createToast(
            `Er is een fout opgetreden bij het doorvoeren van de wijzigingen aan de werkgebieden.`,
            true,
            function () {
              retryFunction();
            }
          );
          reject(err);
        });
    });
  },

  // Sends a http PUT request to the server
  // Updates user's region
  // Return a promise
  // Create a notification based on the response
  bindRegion(userId: string | string[], payload: any, retryFunction: any) {
    return new Promise(function (resolve, reject) {
      http
        .put(`/api/users/${userId}/region`, payload)
        .then((res) => {
          ui.createToast(
            `De gebruiker zijn of haar regio is succesvol gewijzigd!`,
            false
          );
          resolve(res);
        })
        .catch((err) => {
          ui.createToast(
            `Er is een fout opgetreden bij het wijzigen van de regio van de gebruiker.`,
            true,
            function () {
              retryFunction();
            }
          );
          reject(err);
        });
    });
  },

  // Sends a http PUT request to the server
  // Updates user's regions
  // Return a promise
  // Create a notification based on the response
  bindMultipleRegions(
    userId: string | string[],
    payload: any,
    retryFunction: any
  ) {
    return new Promise(function (resolve, reject) {
      http
        .put(`/api/users/${userId}/multiple-regions`, payload)
        .then((res) => {
          ui.createToast(
            `De gebruiker zijn of haar regio's zijn succesvol gewijzigd!`,
            false
          );
          resolve(res);
        })
        .catch((err) => {
          ui.createToast(
            `Er is een fout opgetreden bij het wijzigen van de regio's van de gebruiker.`,
            true,
            function () {
              retryFunction();
            }
          );
          reject(err);
        });
    });
  },

  // Sends a http DELETE request to the server
  // Delete an user based on ID
  // Return a promise
  // Create a notification based on the response
  deleteUser(userId: string, retryFunction: any) {
    return new Promise(function (resolve, reject) {
      http
        .delete(`/api/users/${userId}`)
        .then((res) => {
          ui.createToast(`De gebruiker is succesvol verwijderd`, false);
          resolve(res);
        })
        .catch((err) => {
          ui.createToast(
            "Er is een fout opgetreden bij het verwijderen van de gebruiker",
            true,
            function () {
              retryFunction();
            }
          );
          reject(err);
        });
    });
  },
};
