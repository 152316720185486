import { UserStoreState } from "@/interfaces/users/models";
import { Module } from "vuex";

const usersModule: Module<UserStoreState, UserStoreState> = {
  state: {
    users: null,
    userProfile: null,
    userIndexPagination: null,
    roles: ["admin", "manager", "mechanic", "client"],
    managers: null,
  },
  mutations: {
    setUsers(state, payload) {
      state.users = payload;
    },
    pushUsers(state, payload) {
      if (state.users) {
        state.users = [...state.users, ...payload];
      }
    },
    setUserProfile(state, payload) {
      state.userProfile = payload;
    },
    clearUserProfile(state) {
      state.userProfile = null;
    },
    setUserIndexPagination(state, payload) {
      state.userIndexPagination = payload;
    },
    clearUsers(state) {
      state.users = [];
    },
    updateManagers(state, payload) {
      state.managers = payload;
    },
    clearManagers(state) {
      state.managers = null;
    },
    resetUsersState(state) {
      state.users = null;
      state.userProfile = null;
      state.userIndexPagination = null;
      state.managers = null;
    },
  },
  actions: {
    setUsers(context, payload) {
        context.commit("setUsers", payload.data);
        context.commit("setUserIndexPagination", payload);
    },
    pushUsers(context, payload) {
      context.commit("pushUsers", payload.data);
      context.commit("setUserIndexPagination", payload);
    },
    updateManagers(context, payload) {
      context.commit("updateManagers", payload);
    },
    setUserProfile(context, payload) {
      context.commit("setUserProfile", payload);
    },
    clearUserProfile(context) {
      context.commit("clearUserProfile");
    },
  },
  getters: {
    users(state) {
      return state.users;
    },
    userProfile(state) {
      return state.userProfile;
    },
    userIndexPagination(state) {
      return state.userIndexPagination;
    },
    roles(state) {
      return state.roles;
    },
    managers(state) {
      return state.managers;
    },
  },
};

export default usersModule;
