import { CompanyStoreState } from "@/interfaces/companies/models";
import { Module } from "vuex";

const companiesModule: Module<CompanyStoreState, CompanyStoreState> = {
  state: {
    companies: null,
    companyIndexPagination: null,
  },
  mutations: {
    setCompanies(state, payload) {
      state.companies = payload;
    },
    pushCompanies(state, payload) {
      if (state.companies) {
        state.companies = [...state.companies, ...payload];
      }
    },
    setCompanyIndexPagination(state, payload) {
      state.companyIndexPagination = payload;
    },
    resetCompaniesState(state) {
      state.companies = null;
      state.companyIndexPagination = null;
    },
  },
  actions: {
    setCompanies(context, payload) {
        context.commit("setCompanies", payload.data);
        context.commit("setCompanyIndexPagination", payload);
    },
    pushCompanies(context, payload) {
      context.commit("pushCompanies", payload.data);
      context.commit("setCompanyIndexPagination", payload);
    },
  },
  getters: {
    companies(state) {
      return state.companies;
    },
    companyIndexPagination(state) {
      return state.companyIndexPagination;
    },
  },
};

export default companiesModule;
