
import { defineComponent } from "vue";
import { IonImg } from "@ionic/vue";
import { Page } from "@/interfaces/common/models";

export default defineComponent({
  props: {
    url: String as () => Page["url"],
  },
  components: {
    IonImg,
  },
});
